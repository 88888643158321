@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  min-height: 100vh;
  animation: flyOut 1s ease-in-out;
  color-scheme: rgba(62, 39, 35, 0.719);
  font-family: Calibri  ,sans-serif;
}

::-webkit-scrollbar {
    width: 1px;
}


.title {
    font-family: 'Cookie';
}



::-webkit-scrollbar {
    width: 0px;
}



.info_circle--hidden{
    display: none;
}


  
  

.column-container {
    display: flexbox;
    column-count: 4; 
    column-gap: 2vw; 
    row-gap: 1vh;
    max-width:150vw; 
    min-height: 100vh;
}

.note-card {
    break-inside: avoid;
}



.wrapper {
    display: grid;
    place-items: center;
}

.typing-demo {
    margin-top: 30vh;
    width: 25ch;
    animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0.3vh solid;
    font-family: monospace;
    font-size: 8vh;
}

textarea {
    outline: none;
    width:100%
}



.center-container {
    display: flex;
    justify-content: center;
    margin-top: 1vh;
}


@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

/* error message */
.errmsg {
    color: #ff0000;
    margin-bottom: 2vh;
}

.error-container {
    text-align: center;
    padding: 20px;
    background-color: #ffebee;
    border: 1px solid #ffcdd2;
    border-radius: 5px;
    margin: 20px;
}

.errmsg {
    color: #d32f2f;
    font-weight: bold;
}

.errmsg a {
    color: #1976D2;
    text-decoration: underline;
}

.errmsg a:hover {
    text-decoration: none;
}

.offscreen {
    display: none;
}

.two__button {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.text-button {
    cursor: pointer;
}

.text-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* table */

.table__cell:last-child {
    display: grid;
    place-content: center;
  }
  
  .table__cell--inactive {
    background-color: var(--brown-grey-lite);
  }
  
  .table__cell--active {
    background-color: var(--brown-grey);
  }
  


/* icon */
.icon-button {
    border: none;
    cursor: pointer;
}
  
  .icon-button:disabled {
    opacity: 0.5; 
    cursor: not-allowed;
  }



checkbox input:checked {
    border-color: red;
    background-color:red;
}

/* dash */


.dash-header__container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 6vh;
}

.dash-header__container--small {
    max-width: 100vw;
}

.nav-bar__show{
    display: none;
}


@media screen and (max-width: 640px) {
    .nav-bar__show{
        display: flex;
    }

   .nav-bar{
    position: absolute;
    z-index: 50;
    width: 40%;
    top: 5vh;
    right: 0%;
   }
      
    .column-container {
        column-count: 2;
    }
    .typing-demo {
        margin-top: 10vh;
        font-size: 3vh;
      }
    
      .form {
        max-width: 100%;
        padding: 4vh;
      }
    
      .public {
        padding-top: 17vh;
        max-width: 100vw;
      }

      .edit-note-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 1vw;
        border: 0.1vw solid #ccc;
        box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 80vw;
      }
      .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1.6vw;
      }
    
}

